
/**
 * @roxi/routify 2.18.8
 * File generated Wed Sep 14 2022 02:55:37 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.8"
export const __timestamp = "2022-09-14T02:55:37.647Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import __fallback from '../src/pages/_fallback.svelte'
import _backend from '../src/pages/backend.svelte'
import _env from '../src/pages/env.svelte'
import _errorhandling from '../src/pages/errorhandling.svelte'
import _home from '../src/pages/home.svelte'
import _index from '../src/pages/index.svelte'
import _loggedOut from '../src/pages/logged-out.svelte'
import _login from '../src/pages/login.svelte'
import _mdc from '../src/pages/mdc.svelte'
import _param__number from '../src/pages/param/[number].svelte'
import _progress from '../src/pages/progress.svelte'
import _qs from '../src/pages/qs.svelte'
import _sass from '../src/pages/sass.svelte'
import __layout from '../src/pages/_layout.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => __fallback
    },
    {
      "isPage": true,
      "path": "/backend",
      "id": "_backend",
      "component": () => _backend
    },
    {
      "isPage": true,
      "path": "/env",
      "id": "_env",
      "component": () => _env
    },
    {
      "isPage": true,
      "path": "/errorhandling",
      "id": "_errorhandling",
      "component": () => _errorhandling
    },
    {
      "isPage": true,
      "path": "/home",
      "id": "_home",
      "component": () => _home
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => _index
    },
    {
      "isPage": true,
      "path": "/logged-out",
      "id": "_loggedOut",
      "component": () => _loggedOut
    },
    {
      "isPage": true,
      "path": "/login",
      "id": "_login",
      "component": () => _login
    },
    {
      "isPage": true,
      "path": "/mdc",
      "id": "_mdc",
      "component": () => _mdc
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/param/:number",
          "id": "_param__number",
          "component": () => _param__number
        }
      ],
      "path": "/param"
    },
    {
      "isPage": true,
      "path": "/progress",
      "id": "_progress",
      "component": () => _progress
    },
    {
      "isPage": true,
      "path": "/qs",
      "id": "_qs",
      "component": () => _qs
    },
    {
      "isPage": true,
      "path": "/sass",
      "id": "_sass",
      "component": () => _sass
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => __layout
}


export const {tree, routes} = buildClientTree(_tree)

